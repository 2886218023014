import './Settings/Settings.css'
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import useScreenOrientation from '../Shared/DeviceOrientation.tsx';
import { Operators } from './Settings/Operators.tsx';
import { SettingsContainer } from './Settings/SettingsContainer.tsx';
import { PDFViewer, Document } from '@react-pdf/renderer';
import { Pages } from './PdfGenerator/Pages.tsx';
import { PdfSharedStyles as styles } from '../Shared/Pdf/Stylesheet.jsx';
import { SettingsContext } from './Settings/SettingsContext.tsx';
import { Email } from "react-obfuscate-email";
import AdsenseComponent from '../Shared/AdSense.tsx';

export function MathFactsGenerator({initialSettings}) {    
    const [ settings, setSettings ] = useState(initialSettings);  

    const hidePdfPreview = 
        !(useScreenOrientation().toLowerCase().indexOf("portrait") >= 0) &&
        !isMobile ? '' : 'hidden';  
    
    return(
        <SettingsContext.Provider value={{settings, setSettings}}>
            <AdsenseComponent/>
            <div>                
                <h1>Math Facts Worksheet Generator</h1>
                <Operators/>            
                <SettingsContainer/>
                <Email email="mathpagesrequests@gmail.com">To report a bug or request a feature, please email mathpagesrequests@gmail.com</Email>
                <div id='pdfViewer' className={hidePdfPreview}> 
                    <PDFViewer style={styles.viewer}>
                        <Document title={'Math Facts'}>
                            <Pages settings={settings}/>
                        </Document>
                    </PDFViewer>
                </div>                       
            </div>
        </SettingsContext.Provider>
    );
}